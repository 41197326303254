import React, { useState, createContext } from 'react';
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogContent,
    DialogBody,
    DialogActions,
    Button,
    Input,
    Field,
    makeStyles,
    shorthands,
    Text,
    Spinner
} from '@fluentui/react-components';

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        ...shorthands.gap("2px"),
        width: "550px",
        maxWidth: "100%"
    },
});


function Login({ jwtToken, setJwtToken, setOpen, open }) {
    const [user, setUser] = useState({ userName: "", password: "" });
    const [signingIn, setSigningIn] = useState(false);
    const [result, setResult] = useState("");

    const onLogin = async (e) => {
        try {
            setSigningIn(true);
            const response = await fetch("api/account/login", {
                "method": "POST",
                "headers": {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ userName: user.userName, password: user.password })
            });
            if (response.ok) {
                const token = await response.text();
                setJwtToken(token);
                setResult("JWT Token was set.");
                return true;
            } else {
                const responseBody = await response.json();
                if (responseBody.detail) {
                    setResult(responseBody.detail);
                    return false;
                } else if (responseBody.errors) {
                    let message = "";
                    for (const msg in responseBody.errors) {
                        message += responseBody.errors[msg];
                    }
                    setResult(message);
                    return false;
                } else {
                    setResult("An issue occurred");
                    return false;
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            setSigningIn(false);
        }

    };

    const onUserName = async (e) => {
        setUser({ ...user, userName: e.target.value });
    };

    const onPassword = async (e) => {
        setUser({ ...user, password: e.target.value });
    };

    const handleSubmit = async (ev) => {
        ev.preventDefault();
        const result = await onLogin();
        setOpen(!result);
    };


    return (
        <>
            <Dialog
                modalType="alert"
                open={open}
                onOpenChange={(evt, data) => {
                    setOpen(data.open);
                }}
            >
                <DialogSurface >
                    <form onSubmit={handleSubmit}>
                        <DialogBody>
                            <DialogTitle><Text>Login</Text></DialogTitle>
                            <DialogContent className={useStyles.root}>

                                {signingIn ? <Spinner appearance="primary" label="Logging In" /> : ""}
                                <Field label="User Name" required>
                                    <Input
                                        id="userName"
                                        value={user.userName}
                                        onChange={onUserName}
                                        maxLength={150}
                                        required />
                                </Field>
                                <Field label="Password" >
                                    <Input
                                        id="password"
                                        value={user.password}
                                        onChange={onPassword}
                                        type="password"
                                        maxLength={750}
                                    />
                                </Field>
                                <Field label="Result">
                                    <Input
                                        id="result"
                                        value={result}
                                        readOnly={true}
                                        disabled={true}
                                    />
                                </Field>
                            </DialogContent>
                            <DialogActions>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button appearance="secondary">Close</Button>
                                </DialogTrigger>
                                <Button type="submit" appearance="primary">
                                    Submit
                                </Button>
                            </DialogActions>
                        </DialogBody>
                    </form>
                </DialogSurface>
            </Dialog>
        </>

    );
}

export default Login;