export const data = [
    {
        name: "table of contents",
        id: "toc",
    },
    {
        name: "C# Language",
        id: "csharplang",
        children: [
            {
                name: "records",
                id: "csharprecs",
            },
            {
                name: "with expressions",
                id: "csharpwithexpr",
            },
            {
                name: "deconstructing",
                id: "deconstruct",
            },
            {
                name: "LINQ",
                id: "linq",
                url: "https://learn.microsoft.com/en-us/dotnet/csharp/linq/",
            },
            {
                name: "Asynchronous Programming",
                id: "async",
                url: "https://learn.microsoft.com/en-us/dotnet/csharp/asynchronous-programming/",
            },
        ],
    },
    {
        name: ".net libraries",
        id: ".net",
        children: [
            {
                name: "entity framework",
                id: "efcore",
                children: [
                    {
                        name: "generated properties",
                        id: "efcoregenprop"
                    },
                    {
                        name: "relationships",
                        id: "efrelations"
                    },
                    {
                        name: "migrations",
                        id: "efmigration"
                    }
                ]
            }
        ]
    },
    {
        name: "asp.net general",
        id: "aspnet",
        children: [
            {
                name: "middleware",
                id: "mware",
            },
            {
                name: "filters",
                id: "apifilters",
            },
            {
                name: "logging & errors",
                id: "logginganderrors",
            },
            {
                name: "Configuration",
                id: "config",
            },
            {
                name: "Cacheability",
                id: "cacheability",
            },
            {
                name: "Security",
                id: "security",
            },
            {
                name: "DI",
                id: "di",
                url: "https://learn.microsoft.com/en-us/aspnet/core/fundamentals/dependency-injection?view=aspnetcore-7.0",
            },
            {
                name: "Globalization and localization",
                id: "glo",
                url: "https://learn.microsoft.com/en-us/aspnet/core/fundamentals/localization?view=aspnetcore-7.0",
            },
            {
                name: "Web API Controllers",
                id: "controllers",
                children: [
                    {
                        name: "ControllerBase",
                        id: "controllerbase",
                    },
                ],
            },
            {
                name: "server sent events",
                id: "sse",
            },
            {
                name: "model binding",
                id: "modelbinding",
            },
            {
                name: "validation",
                id: "validation",
            },
            {
                name: "application parts",
                id: "appparts",
            },
            {
                name: "json patch",
                id: "jsonpatch",
            },
            {
                name: "OData",
                id: "odata",
            },
            {
                name: "session state",
                id: "state",
            },
            {
                name: "GRPC-WEB",
                id: "grpc-web"
            },
        ],
    },
    {
        name: "asp.net web api",
        id: "api",
        children: [
            {
                name: "basic crud",
                id: "crud",
            },
            {
                name: "versioning",
                id: "apiversion",
            },
        ],
    },
    {
        name: "css",
        id: "css",
        children: [
            {
                name: "scss",
                id: "scss",
            },
        ],
    },
    {
        name: "html",
        id: "html",
        children: [
            {
                name: "forms",
                id: "forms",
                children: [
                    {
                        name: "controls",
                        id: "html_form_controls"
                    }
                ]
            },
        ],
    },
    {
        name: "react",
        id: "react",
        children: [
            {
                name: "concepts",
                id: "reactconcepts"
            },
            {
                name: "redux saga",
                id: "reduxsaga",
            },
        ],
    },
    {
        name: "javascript",
        id: "js",
        children: [
            {
                name: "generators",
                id: "generators",
            },
            {
                name: "web api's",
                id: "webapis",
            },
        ],
    },
    {
        name: "Demo Applications",
        id: "demoapps",
        children: [
            {
                name: "Vacation Planner",
                id: "vplanner",
                children: [
                    {
                        name: "Companions",
                        id: "companions"
                    },
                    {
                        name: "Airlines",
                        id: "airlines"
                    },
                    {
                        name: "Frequent Flier Account",
                        id: "ffaccount"
                    },
                    {
                        name: "Attractions",
                        id: "attractions"
                    },
                    {
                        name: "Flights",
                        id: "flights"
                    },
                    {
                        name: "Locations",
                        id: "locations"
                    },
                    {
                        name: "Location Document",
                        id: "locationDocuments"
                    },
                    {
                        name: "Lodging",
                        id: "lodgings"
                    },
                    {
                        name: "Trips",
                        id: "trips"
                    }
                ]
            },
            {
                name: "Shopping List",
                id: "shoppinglist",
            },
            {
                name: "Recipes",
                id: "recipes",
            },
        ],
    },
];
