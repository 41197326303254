import clsx from "clsx";
import React, { useEffect, useLayoutEffect, useState, createContext, useRef, lazy, Suspense } from "react";
import "../../App.scss";
import { data } from './data.js';
import { NodeApi, NodeRendererProps, Tree, TreeApi } from "react-arborist";
import { MdArrowDropDown, MdArrowRight } from "react-icons/md";
import { BsMapFill, BsMap, BsGeo, BsGeoFill } from "react-icons/bs";
import styles from './Navigation.module.scss';
import Login from '../api/security/Login';
import { FluentProvider, teamsLightTheme, teamsDarkTheme, webLightTheme, webDarkTheme, teamsHighContrastTheme  } from '@fluentui/react-components';
import { Select, useId, Spinner } from "@fluentui/react-components";
 
export const TokenContext = createContext(null);

const Sass = lazy(async () => {
    return await import("../../Components/css/Sass.jsx");
});
const CrudContainer = lazy(
    async () => await import("../../Components/api/CrudContainer")
);
const FilterContainer = lazy(
    async () => await import("../../Components/api/filters/Component")
);
const MiddleWareContainer = lazy(
    async () => await import("../../Components/api/middleware/Component")
);
const ModelBindingContainer = lazy(
    async () => await import("../../Components/api/modelBinding/Component")
);
const LoggingAndErrorContainer = lazy(
    async () => await import("../../Components/api/loggingAndErrors/Component")
);
const Configuration = lazy(
    async () => await import("../../Components/api/configuration/Component")
);
const Versioning = lazy(
    async () => await import("../../Components/api/versioning/Component")
);
const Cacheability = lazy(
    async () => await import("../../Components/api/cacheability/Component")
);
const Security = lazy(
    async () => await import("../../Components/api/security/Component")
);
const ShoppingList = lazy(
    async () => await import("../../Components/api/shoppingList/Component")
);

const Linq = lazy(async () => {
    return await import("../../Components/csharp/linq/Component.jsx");
});

const ReactConcepts = lazy(async () => {
    return await import("../../Components/react/Concepts.jsx");
});

const EFNav = lazy(async () => {
    return await import("../../Components/entityFramework/Relationships")
});

const EFMigrations = lazy(async () => {
    return await import("../../Components/entityFramework/Migrations")
});

const HTMLFormControls = lazy(async () => {
    return await import("../../Components/html/forms/Controls")
});

const GrpcWeb = lazy(async () => {
    return await import("../../Components/api/grpc-web/Component.jsx");
});



/*********************** TRIPS */
const Trip_Companions = lazy(async () => {
    return await import("../../Components/trips/companions/Form")
});

const Trip_Airlines = lazy(async () => {
    return await import("../../Components/trips/airline/Form")
});

const Trip_Attractions = lazy(async () => {
    return await import("../../Components/trips/attraction/Form")
});

const Trip_Flight = lazy(async () => {
    return await import("../../Components/trips/flight/Form")
});

const Trip_FlightBookingSeat = lazy(async () => {
    return await import("../../Components/trips/flightBookingSeat/Form")
});

const Trip_FrequentFlierAccount = lazy(async () => {
    return await import("../../Components/trips/frequentFlierAccount/Form")
});

const Trip_Location = lazy(async () => {
    return await import("../../Components/trips/location/Form")
});

const Trip_LocationDocument = lazy(async () => {
    return await import("../../Components/trips/locationDocument/Form")
});

const Trip_Lodging = lazy(async () => {
    return await import("../../Components/trips/lodging/Form")
});
const Trip = lazy(async () => {
    return await import("../../Components/trips/trip/Form")
});


/*

const Trip_LodgingStay = lazy(async () => {
    return await import("../../Components/trips/lodgingStay/Form")
});


const Trip_AttractionBooking = lazy(async () => {
    return await import("../../Components/trips/attractionBooking/Form")
});

const Trip_FlightBooking = lazy(async () => {
    return await import("../../Components/trips/flightBooking/Form")
});

const Trip_Day = lazy(async () => {
    return await import("../../Components/trips/tripDay/Form")
});


*/
const INDENT_STEP = 15;

function Node({ node, style, dragHandle }) {
    const Icon = BsMapFill;
    const indentSize = Number.parseFloat(`${style.paddingLeft || 0}`);

    return (
        <div
            ref={dragHandle}
            style={style}
            className={clsx(styles.node, node.state)}
            onClick={() => node.isInternal && node.toggle()}
        >
            {/* 
      <div className={styles.indentLines}>
        {new Array(indentSize / INDENT_STEP).fill(0).map((_, index) => {
          return <div key={index}></div>;
        })}
      </div>
      */}
            <FolderArrow node={node} />
            <Icon className={styles.icon} />{" "}
            <span className={styles.text}>
                {node.data.name}
            </span>
        </div>
    );
}

function FolderArrow({ node }) {
    return (
        <span className={styles.arrow}>
            {node.isInternal ? (
                node.isOpen ? (
                    <MdArrowDropDown />
                ) : (
                    <MdArrowRight />
                )
            ) : null}
        </span>
    );
}

const getThemeFromStorage = () => {
    try {
        const themeId = localStorage.getItem('themeId');
        console.log(`Theme Id in storage: ${themeId}`);
        return themeId;
    } catch (e) {
        console.error(e);
    }
   return 'teamsdark';
}


export default function Navigator() {
    const [focused, setFocused] = useState({});
    const [active, setActive] = useState({ id: "" });
    const [jwtToken, setJwtToken] = useState("");
    const [openLogin, setOpenLogin] = useState(true);
    const [themeId, setThemeId] = useState(getThemeFromStorage());

    const [theme, setTheme] = useState(teamsDarkTheme);
    const themeSelectorId = useId(); 
    const treeRef = useRef(null);

    useLayoutEffect(() => {
        changeTheme(themeId);
    }, [themeId])

    useEffect(() => {
        changeTheme(themeId);
    }, [])



    const onChangeThemeId = (event, data) => {
        try {
            localStorage.setItem('themeId', data.value);
        } catch (e) {
            console.error(e);
        }

        setThemeId(data.value);
    };

    const changeTheme = (themeId) => {
        console.log(`Change theme called with argument: ${themeId}`);
        let defaultTheme = teamsLightTheme;
        switch (themeId){
            case 'weblight': defaultTheme = webLightTheme; break; 
            case 'webdark': defaultTheme = webDarkTheme; break;
            case 'teamslight': defaultTheme = teamsLightTheme; break;
            case 'teamsdark': defaultTheme = teamsDarkTheme; break;
            case 'teamshighcontrast': defaultTheme = teamsHighContrastTheme; break;
        }
        console.log(`about to Change theme called with argument: ${defaultTheme}`);
        setTheme(defaultTheme);
    }

    function RenderActive({ id, component, secure }) {
        return (
            <>
                {secure && jwtToken.length === 0 ?
                    <Login
                        jwtToken={jwtToken}
                        setJwtToken={setJwtToken}
                        open={openLogin}
                        setOpen={setOpenLogin} /> :
                    (
                        active && active.id === id ? (
                            <TokenContext.Provider value={jwtToken }>
                                <Suspense fallback={<Spinner appearance="primary" label="loading" />}>{component}</Suspense>
                            </TokenContext.Provider>
                        ) :
                            ""
                    )}
            </>
        );
    }
    return (
        <FluentProvider theme={theme}>

            <div id="demoContent">
               <div ref={treeRef}>
    
                    <Tree
                        id="navTree"
                        height={1000}
                        openByDefault={false}
                        width={400}
                        rowHeight={36}
                        initialData={data}
                        onActivate={(node) => setActive(node.data)}
                        onFocus={(node) => setFocused(node.data)}
                    >
                        {Node}
                    </Tree>
               </div>

                <div id="content">
                    <div>
                        <label size="small" htmlFor={themeSelectorId}>Theme</label>
                        <Select size="small" id={themeSelectorId} onChange={onChangeThemeId} value={themeId}>
                            <option value={'weblight'}>Web Light</option>
                            <option value={'webdark'}>Web Dark</option>
                            <option value={'teamslight'}>Teams Light</option>
                            <option value={'teamsdark'}>Teams Dark</option>
                            <option value={'teamshighcontrast'}>Teams High Contrast</option>
                        </Select>
                    </div>
 
                    <RenderActive
                        id="toc"
                        component={
                            <p>
                                <a href="tryalldata.html">other stuff</a> can be written in
                                org mode and exported to html, it's much easier to write and
                                edit
                            </p>
                        }
                        secure={false}
                    />
                    <RenderActive id={"scss"} component={<Sass />} secure={false} />

                    <RenderActive id="crud" component={<CrudContainer />} secure={false} />

                    <RenderActive id="apifilters" component={<FilterContainer />} secure={false} />

                    <RenderActive id="mware" component={<MiddleWareContainer />} secure={false} />

                    <RenderActive id="linq" component={<Linq />} secure={false} />

                    <RenderActive id="reactconcepts" component={<ReactConcepts />} secure={false} />

                    <RenderActive
                        id="modelbinding"
                        component={<ModelBindingContainer />}
                        secure={false}
                    />

                    <RenderActive
                        id="logginganderrors"
                        component={<LoggingAndErrorContainer />}
                        secure={false}
                    />

                    <RenderActive id="config" component={<Configuration />} secure={false} />

                    <RenderActive id="apiversion" component={<Versioning />} secure={false} />

                    <RenderActive id="cacheability" component={<Cacheability />} secure={false} />
                    <RenderActive id="security" component={<Security setJwtToken={setJwtToken} jwtToken={jwtToken} />} secure={false} />
                    <RenderActive id="shoppinglist" component={<ShoppingList treeRef={treeRef} />} secure={false} />
                    <RenderActive id="efrelations" component={<EFNav />} secure={false} />
                    <RenderActive id="efmigration" component={<EFMigrations />} secure={false} />

                    <RenderActive id={"html_form_controls"} component={<HTMLFormControls/>} secure={false} />
                    <RenderActive id={"grpc-web"} component={<GrpcWeb />} secure={false} />



                    <RenderActive id="companions" component={<Trip_Companions />} secure={true} />
                    <RenderActive id="airlines" component={<Trip_Airlines />} secure={true} />
                    <RenderActive id="ffaccount" component={<Trip_FrequentFlierAccount secure={true} />} />

                    <RenderActive id="attractions" component={<Trip_Attractions />} secure={true} />
                    <RenderActive id="flights" component={<Trip_Flight />} secure={true} />
                    <RenderActive id="locations" component={<Trip_Location />} secure={true} />
                    <RenderActive id="locationDocuments" component={<Trip_LocationDocument />} secure={true} />
                    <RenderActive id="lodgings" component={<Trip_Lodging />} secure={true} />
                    <RenderActive id="trips" component={<Trip setActive={setActive} />} secure={true} />

                    {/*  
                <RenderActive id="flightBookings" component={<Trip_FlightBooking />} />
                <RenderActive id="flightBookingSeats" component={<Trip_FlightBookingSeat />} />
                <RenderActive id="lodgingStays" component={<Trip_LodgingStay />} />
                <RenderActive id="tripDays" component={<Trip_Day />} />
                <RenderActive id="attractionBookings" component={<Trip_AttractionBooking />} />
                */}

                </div>
            </div>
        </FluentProvider>

    );
}
